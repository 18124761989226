@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Poppins', sans-serif
}

.btn {
  @apply shadow-md py-2 px-10 transition duration-300;
}

.btn-darky {
  @apply text-white bg-slate-900;
}

.radio {
  background: white
}

.radio[data-state='checked'] {
  background: cadetblue;
  color: white
}

.radio[data-state='unchecked'] {
  background: white;
  transition: background 0.2s
}

